@import '../../theme.scss';

#overlay {
	position: relative;
	padding: 0 0;
	margin-top: 100px;
	@include mobile {
		margin-top: 0;
	}
	z-index: 5;
	.bg {
		position: absolute;
		left: 0;
		width: 100%;
		height: 50%;
		z-index: -2;
		&.bg-1 {
			top: 0;
			background-color: $bg-light;
		}
		&.bg-2 {
			bottom: 0;
			background-color: $bg-primary;
		}
	}

	.content {
		z-index: 10;
		min-height: 200px;
		background-color: $bg-light;
		box-shadow: 0px 16px 80px rgba(51, 51, 51, 0.12);
		border-radius: 24px;

		display: grid;
		grid-template-columns: repeat(4, 1fr);
		align-items: center;
    	justify-items: center;

		@include mobile {
			grid-template-columns: 1fr 1fr;
		}

		.infobox {
			height: 100px;
			display: grid;
			grid-template-columns: 60px auto;
			align-items: center;
			@include mobile {
				width: 100%;
			}
			svg {
				fill: $primary;
				height: 40px;
				justify-self: end;
				margin-right: 20px;
				@include mobile {
					height: 30px;
					justify-self: center;
					margin-right: 0;
				}
			}
			.title {
				font-weight: 700;
				font-size: 1.5rem;
			}
			.subtitle {
				font-size: 0.9rem;
				color: $grey;
				font-weight: 600;
				opacity: 0.8;
			}
			@include mobile {
				.title {
					font-size: 1.3rem;
				}
				.subtitle {
					font-size: 0.8rem;
				}
				>div {
					padding-left: 10px;
				}
			}
		}
	}
}