@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './theme.scss';
* {
	font-family: 'Spartan', sans-serif;
	z-index: 10;
}

body, html {
	max-width: 100vw;
	overflow-x: hidden;
}

hr {
	background-color: $primary;
	height: 1px !important;
	opacity: 1;
}