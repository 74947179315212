@import '../../theme.scss';
#regisztracio {
	p.subtitle{
		margin-bottom: 0;
	}
	.reg-form {
		max-width: 700px;
		margin: auto;
		input, textarea {
			margin-bottom: 18px;
		}
	}
	.form-control {
		&:focus {
			box-shadow: 0 0 0 0.1rem $primary;
			border-color: $primary;
		}
	}
}