@import '../../theme.scss';

.bubble {
	border: 2px solid $primary;
	width: 100%;
	//height: 150px;
	aspect-ratio: 1;
	border-radius: 175px;
	display: inline-block;
	position: relative;
	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		width: 150px;
		.title {
			color: $primary;
			font-weight: bold;
			font-size: 2.5rem;
			svg {
				fill: $lightgrey;
			}
		}
		.subtitle {
			color: $dark;
		}
	}

	&.icon {
		.subtitle {
			color: $lightgrey;
			font-weight: 600;
			font-size: 1.2rem;
			padding-top: 20px;
		}
		&.primary {
			.title svg {
				fill: $light;
			}
		}
	}

	&.small-text {
		.title {
			font-size: 1.1rem;
		}
		.subtitle {
			font-size: 0.7rem;
			margin-top: 7px;
		}
	}

	&.dark-text {
		.title {
			color: $dark;
		}
		.subtitle {
			color: $grey;
		}
	}

	
	&.large {
		width: 210px;
		height: 210px;
		border-radius: 125px;
		.content {
			width: 210px;
		}
	}
	&.extra-large {
		width: 270px;
		height: 270px;
		border-radius: 140px;
	}

	&.light {
		border: 0;
		background-color: $light;
	}
	&.primary {
		border: 0;
		background-color: $primary;
		box-shadow: 0px 16px 40px rgba(71, 204, 212, 0.4);
		.title, .subtitle {
			color: $light;
		}
	}

	&.bottom-left {
		border-bottom-left-radius: 0;
	}
	&.bottom-right {
		border-bottom-right-radius: 0;
	}
	&.top-left {
		border-top-left-radius: 0;
	}
	&.top-right {
		border-top-right-radius: 0;
	}

}